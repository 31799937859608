'use strict';

Gri.module({
  name: 'input-gamma',
  ieVersion: null,
  $el: $('.input-gamma'),
  container: '.input-gamma',
  fn: function () {
      new Vue({
        el: '[data-app]',
        data:{
          json: [],
          driverJson: [],
          inputError: false,
          inputErrorBildirim: false,
          valInput: "",
          valInputbildirim: null,
        },
        methods: {
          getClick: function (param, driver) {
            var _valid = true;

            if (!driver) {
              (this.valInput && this.valInputbildirim) ? _valid = true : _valid = false;
            } else {
              (this.valInput || this.valInputbildirim) ? _valid = true : _valid = false;
            }
            // console.log('param', param);
            if(_valid == true){

              // console.log('valInput', this.valInput);
              // console.log('valInputbildirim', this.valInputbildirim);
              this.inputErrorBildirim = false;
              this.inputError = false;
              this.$http.get('/json/'+param+'?serialnumber='+this.valInput+'').then(response => {
                //console.log('param', JSON.parse(response.data).data);
                if (param != 'productdriver') this.json = JSON.parse(response.data).data;

                if (param == 'productdriver') {
                  let jsons = JSON.parse(response.data).data.SubMaterialInfoList;
                  // let results = jsons.filter(json => json.length > jsons.length);
                  // console.log('this.json', this.json);
                  // const dr = this.json.filter()
                  // console.log(dr);
                  this.driverJson = [];

                  for (let value of jsons) {
                    let drivers = value.DRIVERS;

                    if(drivers.length > 0){
                      // console.log('drivers', drivers);
                      this.driverJson.push(drivers.filter( item => item.LINK.length > 20 ));

                    }
                  }
                }

                // console.log('driverJson', this.driverJson)

              }, response => {
                  alert('data-error');
              });
            }else{
              if(this.valInput) {
                this.inputError = false;
              }else{
                this.inputError = true;
              }
              if(this.valInputbildirim){
                this.inputErrorBildirim = false;
              }else{
                this.inputErrorBildirim = true;
              }
            }
          },
        }
      });
     }
});
